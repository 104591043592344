const categories = [
    { id: 1, name: '프레임워크' },
    { id: 2, name: 'AI' },
    { id: 3, name: '클라우드' },
    { id: 4, name: '보안' },
    { id: 5, name: '데이터 사이언스' },
    { id: 6, name: '게임 개발' },
    { id: 7, name: '디지털 디바이스' },
    { id: 8, name: '소프트웨어' },
    { id: 9, name: '모바일' },
]
export default categories
